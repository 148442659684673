// import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="dadJokesHeader">
        <h1 className="headerH1">
          {/* <Link to="/"> */}
          Dad Jokes
          {/* </Link> */}
        </h1>
      </div>
    </>
  );
}

export default Header;
